import React from "react"
import styled from "styled-components"

import Layout from "../components/Layout/Layout"
import { H1 } from "../components/BaseElements/BaseElements"
import Seo from "../components/seo"
import { media } from "../variables"
import lighthouseMobile from "../components/Hero/images/lighthouse-mobile.jpg"
import lighthouse from "../components/Hero/images/lighthouse.jpg"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Page Not Found" />
    <Content>
      <H1>404 PAGE NOT FOUND</H1>
    </Content>
  </Layout>
)

const Content = styled.div`
  height: calc(100vh - 202px);
  min-height: 500px;
  padding: 100px 0;
  margin-bottom: 100px;
  color: #fff;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${lighthouseMobile}) no-repeat;
  background-size: cover;

  ${media.sm`
    background: url(${lighthouse}) no-repeat;
    background-size: cover;
  `}

  > h1 {
    position: relative;
    z-index: 3;
    font-style: italic;
    font-size: 68px;
    line-height: 68px;
    text-align: center;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
    padding: 0 20px 12px 20px;
  }

  &:before {
    position: absolute;
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2) 60%);
    z-index: 1;
  }
`

export default NotFoundPage
